import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPlans } from "../../features/plans/planSlice";
import { getTiers } from "../../features/tiers/tierSlice";
import {
  getUsers,
  reset,
  updateUserSubscription,
} from "../../features/users/userSlice";

const UpdateUserSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasUpdated, setHasUpdate] = useState(false);
  const { users, isSuccess, isError, message } = useSelector(
    (state) => state.users,
  );
  const { plans } = useSelector((state) => state.plans);
  const { tiers } = useSelector((state) => state.tiers);

  useEffect(() => {
    if (users.length === 0) {
      navigate("/admin-dash/users");
    }
    dispatch(getPlans());
    dispatch(getTiers());
    if (isError) {
      toast.error(message);
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      toast.success("User status Updated");
      setHasUpdate(false);
      dispatch(getUsers());
      dispatch(reset());
      navigate("/admin-dash/users");
    }
  }, [dispatch, users, isError, isSuccess, message, navigate, hasUpdated]);

  if (id === null) {
    navigate("/admin-dash/users");
  }
  const user = users.filter((item) => item.id.toString() === id.toString());

  if (user[0] === null) {
    navigate("/admin-dash/users");
  }

  const [formData, setFormData] = useState({
    userId: id,
    planId: "",
    tierId: "",
    startDate: "",
  });

  const { planId, tierId, startDate } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (planId !== "" && tierId !== "" && startDate !== "") {
      setHasUpdate(true);
      dispatch(
        updateUserSubscription({
          userId: id,
          planId,
          tierId,
          startDate,
        }),
      );
      navigate("/admin-dash");
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Update User Subscription</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/users" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>{" "}
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Select new plan</label>
            <select
              name="planId"
              value={planId}
              onChange={onChange}
              className="form-control"
            >
              <option>Select plan</option>
              {plans.map((plan) => (
                <option key={plan?.id} value={plan?.id}>
                  {plan?.title}
                </option>
              ))}
            </select>
            {tiers.length > 0 && (
              <>
                <label>Select new tier</label>
                <select
                  name="tierId"
                  value={tierId}
                  onChange={onChange}
                  className="form-control"
                >
                  <option>Select tier</option>
                  {tiers.map((tier) => (
                    <option key={tier?.id} value={tier?.id}>
                      {tier?.months} - {tier?.price}
                    </option>
                  ))}
                </select>
              </>
            )}
            <label>New subscription start date</label>
            <input
              type="date"
              className="form-control"
              name="startDate"
              onChange={onChange}
              value={startDate}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update Subscription
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateUserSubscription;
