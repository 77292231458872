import { Link } from "react-router-dom";

const ServiceItem = ({ service }) => {
  const { id, name } = service;

  return (
    <div className="service-item">
      <div className="service-name">{name}</div>
      <Link to={`/admin-dash/edit-service/${id}`}>
        <button className="item-btn">Edit</button>
      </Link>
    </div>
  );
};

export default ServiceItem;
