import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/schedules`;

const createSchedule = async (scheduleData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, scheduleData, config);

  return response.data;
};

const getOpenHours = async (day) => {
  const response = await axios.get(`${API_URL}?day_of_week=${day}`);

  return response.data;
};

const getSchedules = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

const updateSchedule = async (scheduleData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${scheduleData.id}`,
    scheduleData,
    config,
  );
  return response.data;
};

const deleteSchedule = async (scheduleId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${scheduleId}`, config);
  return response.data;
};
const scheduleService = {
  createSchedule,
  getSchedules,
  getOpenHours,
  updateSchedule,
  deleteSchedule,
};

export default scheduleService;
