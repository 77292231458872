import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword, reset } from "../../features/auth/authSlice";

const ChangePasswordAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasUpdated, setHasUpdate] = useState(false);
  const { user, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (!user || !user.role) {
      navigate("/login");
    }
    if (isError) {
      toast.error(message);
      dispatch(reset());
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      toast.success("Password updated");
      navigate("/admin-dash/admin-account");
      setHasUpdate(false);
      dispatch(reset());
    }
  }, [user, hasUpdated, navigate, dispatch, isError, isSuccess, message]);

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const { oldPassword, newPassword, confirmPassword } = formData;

  const onSubmit = (e) => {
    e.preventDefault();

    if (oldPassword !== "" && newPassword !== "" && confirmPassword !== "") {
      setHasUpdate(true);
      dispatch(
        changePassword({
          oldPassword,
          newPassword,
          confirmPassword,
        })
      );
      setFormData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>My Account</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/admin-account">
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Old Password</label>
            <input
              type="password"
              name="oldPassword"
              value={oldPassword}
              onChange={onChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={onChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Confirm New Password</label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={onChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordAdmin;
