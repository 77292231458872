import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/plans`;

const createPlan = async (planData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, planData, config);

  return response.data;
};

const getPlans = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

const updatePlan = async (planData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${planData.id}`,
    planData,
    config,
  );
  return response;
};

const deletePlan = async (serviceId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${serviceId}`, config);
  return response;
};

const planService = {
  createPlan,
  getPlans,
  updatePlan,
  deletePlan,
};

export default planService;
