import { Link } from "react-router-dom";

const PlansItem = ({ plan }) => {
  const { id, title } = plan;
  return (
    <div className="service-item">
      <div className="service-name">{title}</div>
      <Link to={`/admin-dash/edit-plan/${id}`}>
        <button className="item-btn">Edit</button>
      </Link>
    </div>
  );
};

export default PlansItem;
