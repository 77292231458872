import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/services`;

const createService = async (serviceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, serviceData, config);

  return response.data;
};

const getServices = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

const updateService = async (serviceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${serviceData.id}`,
    serviceData,
    config,
  );
  return response;
};

const deleteService = async (serviceId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${serviceId}`, config);
  return response;
};

const serviceService = {
  createService,
  getServices,
  updateService,
  deleteService,
};

export default serviceService;
