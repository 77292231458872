import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ScheduleItem from "../../components/admin/ScheduleItem";
import { getSchedules } from "../../features/schedules/scheduleSlice";

const Schedules = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { schedules } = useSelector((state) => state.schedules);
  useEffect(() => {
    dispatch(getSchedules());
    if (!user || !user.role) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);
  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Schedules</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/add-schedule">
          <button className="btn btn-sm btn-sm-dark">Add Schedule</button>
        </Link>
        <Link to="/admin-dash/timeoffs" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Time offs</button>
        </Link>
        {schedules.length > 0 ? (
          <div className="services-wrapper">
            {schedules.map((item, index) => (
              <ScheduleItem key={index} schedule={item} />
            ))}
          </div>
        ) : (
          <p>No Schedule in the database</p>
        )}
      </div>
    </div>
  );
};

export default Schedules;
