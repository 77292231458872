import axios from "axios";
import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/users`;

const getUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL, config);

  return response.data;
};

const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}/${userId}`, config);
  return response.data;
};

const updateStatus = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/update-status`,
    userData,
    config,
  );
  return response.data;
};

const choosePlan = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${API_URL}/assign-plan`, userData, config);
  console.log(API_URL);
  return response.data;
};

const activateSubscription = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/activate-subscription`,
    userData,
    config,
  );
  return response.data;
};

const updateUserSubscription = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/update-subscription`,
    userData,
    config,
  );

  return response.data;
};

const userService = {
  getUsers,
  updateStatus,
  choosePlan,
  deleteUser,
  activateSubscription,
  updateUserSubscription,
};

export default userService;
