import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createTimeoff } from "../../features/timeoff/timeoffSlice";

const AddTimeoff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    timeoff_date: "",
  });

  const { timeoff_date } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (timeoff_date !== "") {
      dispatch(
        createTimeoff({
          timeoff_date,
        })
      );
      toast.success("Timeoff Added!");
      navigate("/admin-dash/timeoffs");
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Add Timeoff</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/timeoffs">
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Time off date</label>
            <input
              type="date"
              name="timeoff_date"
              onChange={onChange}
              value={timeoff_date}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Add Timeoff
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTimeoff;
