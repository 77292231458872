import { useEffect } from "react";
import {
  FaCalendarAlt,
  FaCogs,
  FaPlusCircle,
  FaRegCreditCard,
  FaUserAlt,
  FaUsersCog,
} from "react-icons/fa";
import { HiSparkles } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!user || !user.role) {
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    <>
      <AdminHeader />
      <div className="dashboard-wrapper">
        <div className="quick-actions">
          {user !== null ? (
            <div className="quick-actions-header">
              <h1>Welcome {user.name}</h1>
              <br />
            </div>
          ) : null}
          <div className="quick-actions-header">
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#fb9230", margin: "0 4px" }}>
                <HiSparkles />
              </span>
              Quick actions
            </h1>
          </div>
          <div className="quick-actions-body">
            <Link to="/admin-dash/schedules">
              <button className="action-btn">
                <FaCalendarAlt />
                <span>Schedules</span>
              </button>
            </Link>
            <Link to="/admin-dash/services">
              <button className="action-btn">
                <FaCogs />
                <span>Services</span>
              </button>
            </Link>
            <Link to="/admin-dash/addons">
              <button className="action-btn">
                <FaPlusCircle />
                <span>Addons</span>
              </button>
            </Link>
            <Link to="/admin-dash/subscriptions">
              <button className="action-btn">
                <FaRegCreditCard />
                <span>Subscriptions</span>
              </button>
            </Link>
            <Link to="/admin-dash/users">
              <button className="action-btn">
                <FaUsersCog />
                <span>Users</span>
              </button>
            </Link>
            <Link to="/admin-dash/admin-account">
              <button className="action-btn">
                <FaUserAlt />
                <span>My Account</span>
              </button>
            </Link>
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default AdminDashboard;
