import { Link } from "react-router-dom";

const AddonItem = ({ addon }) => {
  const { id, name } = addon;

  return (
    <div className="service-item">
      <div className="service-name">{name}</div>
      <Link to={`/admin-dash/edit-addon/${id}`}>
        <button className="item-btn">Edit</button>
      </Link>
    </div>
  );
};

export default AddonItem;
