import "./Main.css";
import logo from "../assets/logo_pic.PNG";
import { FaSignOutAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
const WaitingForAccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };
  return (
    <div className="container">
      <section className="heading">
        <div className="logo">
          <img src={logo} alt="Kingz Barbershop" />
        </div>
        <div className="contents">
          <h1>Waiting for access</h1>
          <center>
            <p>
              Access will be granted next time you visit the shop and make a
              payment.
            </p>
            <p>
              If you did not choose a plan click here{" "}
              <strong>
                <Link to="/choose-plan" style={{ color: "#fb8500" }}>
                  Choose plan
                </Link>
              </strong>
            </p>
            <button className="btn btn-sm btn-block" onClick={onLogout}>
              <FaSignOutAlt /> <span>Logout</span>
            </button>
          </center>
        </div>
      </section>
    </div>
  );
};

export default WaitingForAccess;
