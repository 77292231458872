import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPlans } from "../features/plans/planSlice";
import "./Plans.css";
import Spinner from "../components/Spinner";
import { choosePlan } from "../features/users/userSlice";
import { toast } from "react-toastify";

const ChooseTier = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { plans, isError, isLoading, message } = useSelector(
    (state) => state.plans
  );

  useEffect(() => {
    dispatch(getPlans());
    if (isError) {
      console.log(message);
    }
    dispatch(getPlans());
  }, [dispatch, isError, message]);

  if (id === null) {
    navigate("/choose-plans");
  }

  const [tierSet, setTierSet] = useState("");

  const plan = plans.filter((item) => item.id.toString() === id.toString());

  const onSubmit = (e) => {
    e.preventDefault();
    // validate data.
    if (tierSet !== "") {
      dispatch(
        choosePlan({
          planId: id,
          tierId: tierSet,
        })
      );
      navigate("/waiting-for-confirmation");
      toast.success("Plan Saved");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="plans-container">
      <br />
      <h1 style={{ color: "orange" }}>Choose Tier</h1>
      <br />
      <div className="plans-wrapper">
        <div className="center-items">
          <h2 style={{ textAlign: "center", margin: "10px 0" }}>
            {plan[0]?.title}
          </h2>
          {plan[0]?.tiers && plan[0]?.tiers.length > 0 ? (
            plan[0]?.tiers.map((tier, index) => (
              <div
                className="plan-item"
                key={index}
                onClick={() => setTierSet(tier.id)}
              >
                {tier.months} - {`${parseInt(tier.price)} PLN`}
              </div>
            ))
          ) : (
            <p>No Tiers assigned</p>
          )}
          {tierSet !== "" ? (
            <>
              <p>
                Chosen plan:{" "}
                {
                  plan[0]?.tiers.filter(
                    (item) => item.id.toString() === tierSet.toString()
                  )[0]?.months
                }{" "}
              </p>
              <button className="btn btn-block" onClick={onSubmit}>
                Confirm Plan
              </button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ChooseTier;
