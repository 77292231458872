import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import addonService from "./addonService";

// initial state.
const initialState = {
  addons: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new addon.
export const createAddon = createAsyncThunk(
  "addon/create",
  async (addonData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await addonService.createAddon(addonData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAddons = createAsyncThunk(
  "addon/getAll",
  async (_, thunkAPI) => {
    try {
      return await addonService.getAddons();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAddon = createAsyncThunk(
  "addon/update",
  async (addonData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await addonService.updateAddon(addonData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAddon = createAsyncThunk(
  "addon/delete",
  async (addonId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await addonService.deleteAddon(addonId, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addonSlice = createSlice({
  name: "addon",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAddon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addons.push(action.payload);
      })
      .addCase(createAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAddons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addons = action.payload;
      })
      .addCase(getAddons.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateAddon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteAddon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = addonSlice.actions;
export default addonSlice.reducer;
