import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/tiers`;

const createTier = async (tierData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, tierData, config);

  return response.data;
};

const getTiers = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

const getOneTier = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);

  return response.data;
};

const updateTier = async (tierData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${tierData.id}`,
    tierData,
    config,
  );
  return response;
};

const deleteTier = async (tierId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${tierId}`, config);
  return response;
};

const addonService = {
  getTiers,
  getOneTier,
  createTier,
  deleteTier,
  updateTier,
};

export default addonService;
