import { Link } from "react-router-dom";

const UserItem = ({ user }) => {
  const { id, name } = user;
  return (
    <div className="service-item">
      <div className="service-name">
        <strong>{name}</strong>
      </div>
      <Link to={`/admin-dash/edit-user/${id}`}>
        <button className="item-btn">Edit</button>
      </Link>
    </div>
  );
};

export default UserItem;
