import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/appointments`;

const createAppointment = async (appointmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/book-member`,
    appointmentData,
    config,
  );

  return response.data;
};

const getAppointments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

const deleteAppointment = async (appointmentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${appointmentId}`, config);
  return response.data;
};

const appointmentService = {
  createAppointment,
  getAppointments,
  deleteAppointment,
};

export default appointmentService;
