import { Link } from "react-router-dom";

const AppointmentItem = ({ appointment }) => {
  const newReturnedDate = new Date(appointment.appointment_date).toISOString();
  const timeZone = new Date().getTimezoneOffset() / 60;
  const timeArray = newReturnedDate.split("T")[1].split(":");
  const hourFromTime = timeArray[0];
  const newHour = parseInt(hourFromTime) + timeZone;

  const newTime = `${("0" + newHour).slice(-2)}:${timeArray[1]}:00.000Z`;
  const newDisplayDate = new Date(
    `${newReturnedDate.split("T")[0]}T${newTime}`,
  );

  let displayTime = newDisplayDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  let displayDate = newDisplayDate.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
  });
  return (
    <div className="appointment-item">
      <div>
        <div className="appointment-name">
          {appointment.name} : {appointment.service.name}
        </div>
        <div className="appointment-date">
          {displayDate}: {displayTime}
        </div>
      </div>
      <div className="appointment-btn">
        <Link to={`/view-appointment/${appointment.id}`}>
          <button className="btn btn-sm">Details</button>
        </Link>
      </div>
    </div>
  );
};

export default AppointmentItem;
