export const times = [
  {
    time: "00:45:00.000Z",
    displayTime: "12:45 AM",
    searchTimes: ["00:00:00.000Z", "00:45:00.000Z", "01:30:00.000Z"],
  },
  {
    time: "01:30:00.000Z",
    displayTime: "1:30 AM",
    searchTimes: ["00:45:00.000Z", "01:30:00.000Z", "02:15:00.000Z"],
  },
  {
    time: "02:15:00.000Z",
    displayTime: "2:15 AM",
    searchTimes: ["01:30:00.000Z", "02:15:00.000Z", "03:00:00.000Z"],
  },
  {
    time: "03:00:00.000Z",
    displayTime: "3:00 AM",
    searchTimes: ["02:15:00.000Z", "03:00:00.000Z", "03:45:00.000Z"],
  },
  {
    time: "03:45:00.000Z",
    displayTime: "3:45 AM",
    searchTimes: ["03:00:00.000Z", "03:45:00.000Z", "04:30:00.000Z"],
  },
  {
    time: "04:30:00.000Z",
    displayTime: "4:30 AM",
    searchTimes: ["03:45:00.000Z", "04:30:00.000Z", "05:15:00.000Z"],
  },
  {
    time: "05:15:00.000Z",
    displayTime: "5:15 AM",
    searchTimes: ["04:30:00.000Z", "05:15:00.000Z", "06:00:00.000Z"],
  },
  {
    time: "06:00:00.000Z",
    displayTime: "6:00 AM",
    searchTimes: ["05:15:00.000Z", "06:00:00.000Z", "06:45:00.000Z"],
  },
  {
    time: "06:45:00.000Z",
    displayTime: "6:45 AM",
    searchTimes: ["06:00:00.000Z", "06:45:00.000Z", "07:30:00.000Z"],
  },
  {
    time: "07:30:00.000Z",
    displayTime: "7:30 AM",
    searchTimes: ["06:45:00.000Z", "07:30:00.000Z", "08:15:00.000Z"],
  },
  {
    time: "08:15:00.000Z",
    displayTime: "8:15 AM",
    searchTimes: ["07:30:00.000Z", "08:15:00.000Z", "09:00:00.000Z"],
  },
  {
    time: "09:00:00.000Z",
    displayTime: "9:00 AM",
    searchTimes: ["08:15:00.000Z", "09:00:00.000Z", "09:45:00.000Z"],
  },
  {
    time: "09:45:00.000Z",
    displayTime: "9:45 AM",
    searchTimes: ["09:00:00.000Z", "09:45:00.000Z", "10:30:00.000Z"],
  },
  {
    time: "10:30:00.000Z",
    displayTime: "10:30 AM",
    searchTimes: ["09:45:00.000Z", "10:30:00.000Z", "11:15:00.000Z"],
  },
  {
    time: "11:15:00.000Z",
    displayTime: "11:15 AM",
    searchTimes: ["10:30:00.000Z", "11:15:00.000Z", "12:00:00.000Z"],
  },
  {
    time: "12:00:00.000Z",
    displayTime: "12:00 PM",
    searchTimes: ["11:15:00.000Z", "12:00:00.000Z", "12:45:00.000Z"],
  },
  {
    time: "12:45:00.000Z",
    displayTime: "12:45 PM",
    searchTimes: ["12:00:00.000Z", "12:45:00.000Z", "13:30:00.000Z"],
  },
  {
    time: "13:30:00.000Z",
    displayTime: "1:30 PM",
    searchTimes: ["12:45:00.000Z", "13:30:00.000Z", "14:15:00.000Z"],
  },
  {
    time: "14:15:00.000Z",
    displayTime: "2:15 PM",
    searchTimes: ["13:30:00.000Z", "14:15:00.000Z", "15:00:00.000Z"],
  },
  {
    time: "15:00:00.000Z",
    displayTime: "3:00 PM",
    searchTimes: ["14:15:00.000Z", "15:00:00.000Z", "15:45:00.000Z"],
  },
  {
    time: "15:45:00.000Z",
    displayTime: "3:45 PM",
    searchTimes: ["15:00:00.000Z", "15:45:00.000Z", "16:30:00.000Z"],
  },
  {
    time: "16:30:00.000Z",
    displayTime: "4:30 PM",
    searchTimes: ["15:45:00.000Z", "16:30:00.000Z", "17:15:00.000Z"],
  },
  {
    time: "17:15:00.000Z",
    displayTime: "5:15 PM",
    searchTimes: ["16:30:00.000Z", "17:15:00.000Z", "18:00:00.000Z"],
  },
  {
    time: "18:00:00.000Z",
    displayTime: "6:00 PM",
    searchTimes: ["17:15:00.000Z", "18:00:00.000Z", "18:45:00.000Z"],
  },
  {
    time: "18:45:00.000Z",
    displayTime: "6:45 PM",
    searchTimes: ["18:00:00.000Z", "18:45:00.000Z", "19:30:00.000Z"],
  },
  {
    time: "19:30:00.000Z",
    displayTime: "7:30 PM",
    searchTimes: ["18:45:00.000Z", "19:30:00.000Z", "20:15:00.000Z"],
  },
  {
    time: "20:15:00.000Z",
    displayTime: "8:15 PM",
    searchTimes: ["19:30:00.000Z", "20:15:00.000Z", "21:00:00.000Z"],
  },
  {
    time: "21:00:00.000Z",
    displayTime: "9:00 PM",
    searchTimes: ["20:15:00.000Z", "21:00:00.000Z", "21:45:00.000Z"],
  },
  {
    time: "21:45:00.000Z",
    displayTime: "9:45 PM",
    searchTimes: ["21:00:00.000Z", "21:45:00.000Z", "22:30:00.000Z"],
  },
  {
    time: "22:30:00.000Z",
    displayTime: "10:30 PM",
    searchTimes: ["21:45:00.000Z", "22:30:00.000Z", "23:15:00.000Z"],
  },
  {
    time: "23:15:00.000Z",
    displayTime: "11:15 PM",
    searchTimes: ["22:30:00.000Z", "23:15:00.000Z", "00:00:00.000Z"],
  },
];
