const displayTimeObject = {
  "00:00:00.000Z": "12:00 AM",
  "00:30:00.000Z": "12:30 AM",
  "01:00:00.000Z": "1:00 AM",
  "01:30:00.000Z": "1:30 AM",
  "02:00:00.000Z": "2:00 AM",
  "02:30:00.000Z": "2:30 AM",
  "03:00:00.000Z": "3:00 AM",
  "03:30:00.000Z": "3:30 AM",
  "04:00:00.000Z": "4:00 AM",
  "04:30:00.000Z": "4:30 AM",
  "05:00:00.000Z": "5:00 AM",
  "05:30:00.000Z": "5:30 AM",
  "06:00:00.000Z": "6:00 AM",
  "06:30:00.000Z": "6:30 AM",
  "07:00:00.000Z": "7:00 AM",
  "07:30:00.000Z": "7:30 AM",
  "08:00:00.000Z": "8:00 AM",
  "08:30:00.000Z": "8:30 AM",
  "09:00:00.000Z": "9:00 AM",
  "09:30:00.000Z": "9:30 AM",
  "10:00:00.000Z": "10:00 AM",
  "10:30:00.000Z": "10:30 AM",
  "11:00:00.000Z": "11:00 AM",
  "11:30:00.000Z": "11:30 AM",
  "12:00:00.000Z": "12:00 PM",
  "12:30:00.000Z": "12:30 PM",
  "13:00:00.000Z": "1:00 PM",
  "13:30:00.000Z": "1:30 PM",
  "14:00:00.000Z": "2:00 PM",
  "14:30:00.000Z": "2:30 PM",
  "15:00:00.000Z": "3:00 PM",
  "15:30:00.000Z": "3:30 PM",
  "16:00:00.000Z": "4:00 PM",
  "16:30:00.000Z": "4:30 PM",
  "17:00:00.000Z": "5:00 PM",
  "17:30:00.000Z": "5:30 PM",
  "18:00:00.000Z": "6:00 PM",
  "18:30:00.000Z": "6:30 PM",
  "19:00:00.000Z": "7:00 PM",
  "19:30:00.000Z": "7:30 PM",
  "20:00:00.000Z": "8:00 PM",
  "20:30:00.000Z": "8:30 PM",
  "21:00:00.000Z": "9:00 PM",
  "21:30:00.000Z": "9:30 PM",
  "22:00:00.000Z": "10:00 PM",
  "22:30:00.000Z": "10:30 PM",
  "23:00:00.000Z": "11:00 PM",
  "23:30:00.000Z": "11:30 PM",
};

export const convertToDisplayTime = (time) => {
  return displayTimeObject[time];
};

export function formatTime(timeString) {
  if (timeString) {
    // Making a new date object with passed time value
    const fullDate = new Date().toISOString();
    const newDate = new Date(`${fullDate.split("T")[0]}T${timeString}`);

    // Converting returned date into ISO string to get timezones
    const newReturnedDate = new Date(newDate).toISOString();

    // Getting current timezone
    const timeZone = new Date().getTimezoneOffset() / 60;

    // Splitting array to get hours in order to subtract timezone difference
    const timeArray = newReturnedDate.split("T")[1].split(":");
    const hourFromTime = timeArray[0];
    const newHour = parseInt(hourFromTime) + timeZone;

    // Constructing new date object with calculated time - timezone difference
    const newTime = `${`0${newHour}`.slice(-2)}:${timeArray[1]}:00.000Z`;
    const newDisplayDate = new Date(
      `${newReturnedDate.split("T")[0]}T${newTime}`
    );

    // Formatting time
    const formattedDateTime = newDisplayDate.toLocaleString("en-Us", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const formattedTime = formattedDateTime.toString().split(",");

    // returning formatted time
    return formattedTime;
  }
}
