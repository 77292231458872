import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppointmentItem from "../../components/admin/AppointmentItem";
import Spinner from "../../components/Spinner";
import {
  getAppointments,
  reset,
} from "../../features/appointments/appointmentSlice";
import { getTiers } from "../../features/tiers/tierSlice";

const HeroAdminDash = () => {
  const dispatch = useDispatch();
  const { appointments, isError, isLoading, message } = useSelector(
    (state) => state.appointments,
  );
  let uniqueObjArr = [];

  useEffect(() => {
    dispatch(getAppointments());
    dispatch(getTiers());

    return () => {
      dispatch(reset);
    };
  }, [isError, message, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  if (appointments.length > 0) {
    uniqueObjArr = [
      ...new Map(
        appointments.map((item) => [item["appointment_date"], item]),
      ).values(),
    ];
  }

  return (
    <div className="quick-actions">
      <br />
      <div className="quick-actions-header">
        <h1>Client Appointments</h1>
      </div>
      <div className="quick-actions-body">
        {uniqueObjArr.length > 0 ? (
          <div className="appointments-wrapper">
            {uniqueObjArr.map((appointment, index) => (
              <AppointmentItem key={index} appointment={appointment} />
            ))}
          </div>
        ) : (
          <p>There are no client appointments yet</p>
        )}
      </div>
    </div>
  );
};

export default HeroAdminDash;
