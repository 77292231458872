import { useState, useEffect } from "react";
import { CgDanger } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopUp from "../components/helpers/PopUp";
import {
  deleteAppointment,
  getAppointments,
} from "../features/appointments/appointmentSlice";

const ViewClientAppointment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appointments, isSuccess, isError, message } = useSelector(
    (state) => state.appointments
  );
  const [hasUpdated, setHasUpdate] = useState(false);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      setHasUpdate(false);
      dispatch(getAppointments());
      navigate("/");
    }
  }, [dispatch, isError, isSuccess, message, navigate, hasUpdated]);

  const appointment = appointments.filter(
    (item) => parseInt(item.id) === parseInt(id)
  );

  const name = appointment[0]?.name;
  const appointment_date = new Date(appointment[0]?.appointment_date);
  const phone_number = appointment[0]?.phone_number;
  const email = appointment[0]?.email;

  let displayTime = appointment_date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  let displayDate = appointment_date.toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // Pop up state.
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    dispatch(deleteAppointment(id));
    toast.success("Appointment Deleted!");
    navigate("/");
    dispatch(getAppointments());
  };

  return (
    <div className="quick-actions">
      {showPopUp ? (
        <PopUp>
          <div className="popup-header">
            <CgDanger />
          </div>
          <div className="popup-body">
            Are you sure you want to delete this appointment
            <div className="popup-btns">
              <button className="btn btn-sm btn-del" onClick={confirmDelete}>
                Confirm
              </button>
              <button className="btn btn-sm btn-sm-dark" onClick={togglePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      ) : null}
      <div className="quick-actions-header">
        <h1>View Appointment</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>{" "}
        <button className="btn btn-sm btn-del" onClick={togglePopUp}>
          Delete Appointment
        </button>
        <form className="form">
          <div className="form-group">
            <strong>Name</strong>
            <p>{name}</p>
          </div>
          <div className="form-group">
            <strong>Appointment date</strong>
            <p>
              {displayDate}, {displayTime}
            </p>
          </div>
          <div className="form-group">
            <strong>Phone Number</strong>
            <p>{phone_number}</p>
          </div>
          <div className="form-group">
            <strong>Email</strong>
            <p>{email}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewClientAppointment;
