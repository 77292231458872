import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getTiers, reset, updateTier } from "../../features/tiers/tierSlice";
import { getPlans } from "../../features/plans/planSlice";

const UpdateTier = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasUpdated, setHasUpdate] = useState(false);
  const { tiers, isSuccess, isError, message } = useSelector(
    (state) => state.tiers
  );

  useEffect(() => {
    if (tiers.length === 0) {
      navigate("/admin-dash/subscriptions");
    }
    if (isError) {
      toast.error(message);
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      toast.success("Package Updated");
      setHasUpdate(false);
      dispatch(getTiers());
      dispatch(reset());
      navigate("/admin-dash/subscriptions");
    }
  }, [dispatch, tiers, isError, isSuccess, message, navigate, hasUpdated]);

  if (id === null) {
    navigate("/admin-dash/subscriptions");
  }
  const tier = tiers.filter((item) => item.id.toString() === id.toString());

  const [formData, setFormData] = useState({
    months: tier[0]?.months,
    price: tier[0]?.price,
  });

  const { months, price } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (months !== "" && price !== "") {
      setHasUpdate(true);
      dispatch(
        updateTier({
          id,
          months,
          price,
        })
      );
      dispatch(getPlans());
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Update Addon</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/subscriptions" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Cancel</button>
        </Link>{" "}
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Addon Name</label>
            <input
              type="text"
              name="months"
              onChange={onChange}
              value={months}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              onChange={onChange}
              value={price}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update Package
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateTier;
