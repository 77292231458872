import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import appointmentReducer from "../features/appointments/appointmentSlice";
import serviceReducer from "../features/services/serviceSlice";
import addonReducer from "../features/addons/addonSlice";
import scheduleReducer from "../features/schedules/scheduleSlice";
import availabilityReducer from "../features/availabilities/availabilitySlice";
import planReducer from "../features/plans/planSlice";
import userReducer from "../features/users/userSlice";
import tierReducer from "../features/tiers/tierSlice";
import timeoffReducer from "../features/timeoff/timeoffSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appointments: appointmentReducer,
    services: serviceReducer,
    addons: addonReducer,
    schedules: scheduleReducer,
    availabilities: availabilityReducer,
    plans: planReducer,
    users: userReducer,
    tiers: tierReducer,
    timeoffs: timeoffReducer,
  },
});
