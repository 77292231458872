import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/availability`;

const getAvailabilities = async (scheduleData) => {
  const response = await axios.get(
    `${API_URL}?day=${scheduleData.day}&time=${scheduleData.time}`,
  );

  return response.data;
};

const availabilityService = {
  getAvailabilities,
};

export default availabilityService;
