import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TimeoffItem from "../../components/admin/TimeoffItem";
import { getTimeoffs } from "../../features/timeoff/timeoffSlice";

const Timeoffs = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { timeoffs } = useSelector((state) => state.timeoffs);

  useEffect(() => {
    dispatch(getTimeoffs());
    if (!user || !user.role) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);
  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Timeoffs</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/schedules">
          <button className="btn btn-sm btn-sm-dark">Return</button>
        </Link>
        <Link to="/admin-dash/add-timeoff" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Add Timeoff</button>
        </Link>
        {timeoffs.length > 0 ? (
          <div className="services-wrapper">
            {timeoffs.map((item, index) => (
              <TimeoffItem key={index} timeoff={item} />
            ))}
          </div>
        ) : (
          <p>No Timeoffs in the future</p>
        )}
      </div>
    </div>
  );
};

export default Timeoffs;
