import {
  FaBars,
  FaCogs,
  FaHome,
  FaSignInAlt,
  FaSignOutAlt,
  FaTimes,
  FaUser,
  FaUsersCog,
} from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { reset, logout } from "../features/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { useEffect, useState } from "react";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const [screenSize, getScreenSize] = useState(window.innerWidth);

  const setScreenSize = () => {
    getScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setScreenSize);

    return () => {
      window.removeEventListener("resize", setScreenSize);
    };
  }, [screenSize]);

  return (
    <nav>
      <div className="wrapper">
        <div className="logo">
          <Link to="/admin-dash">Kingz Barbershop</Link>
        </div>
        <div className="burger-menu" onClick={handleMenu}>
          {openMenu === false ? <FaBars /> : <FaTimes />}
        </div>
        <ul
          style={
            openMenu === true
              ? screenSize <= 840
                ? { display: "flex" }
                : { display: "flex" }
              : screenSize <= 840
              ? { display: "none" }
              : { display: "flex" }
          }
        >
          {user ? (
            <>
              <li>
                <Link to="/admin-dash">
                  <button className="btn">
                    <FaHome /> <span>Home</span>
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/admin-dash/services">
                  <button className="btn">
                    <FaCogs /> <span>Services</span>
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/admin-dash/users">
                  <button className="btn">
                    <FaUsersCog /> <span>Manage users</span>
                  </button>
                </Link>
              </li>
              <li>
                <button className="btn" onClick={onLogout}>
                  <FaSignOutAlt /> <span>Logout</span>
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login">
                  <FaSignInAlt /> Login
                </Link>
              </li>
              <li>
                <Link to="/register">
                  <FaUser /> Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
