import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTiers } from "../features/tiers/tierSlice";

const MyAccount = () => {
  const { user } = useSelector((state) => state.auth);
  const { tiers, isError, message } = useSelector((state) => state.tiers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTiers());
  }, [dispatch, isError, message]);
  console.log(user);

  const name = user?.name;
  const email = user?.email;
  const plan = user?.plans[0];

  const tier = tiers.filter(
    (item) => item.id.toString() === plan?.membership?.tierId?.toString()
  );

  return (
    <>
      <div className="quick-actions">
        <div className="quick-actions-header">
          <h1>Personal Information</h1>
        </div>
        <div className="quick-actions-body">
          <div className="info-wrapper">
            <div>
              <label>Name:</label> {name}
            </div>
            <div>
              <label>Email:</label> {email}
            </div>
            <br />
            <div className="form-group package-item">
              <strong>Package</strong>
              {tier.length > 0 ? (
                <p>
                  {plan?.title} - {tier[0]?.months} ({parseInt(tier[0].price)}{" "}
                  PLN)
                </p>
              ) : (
                <p>No plan chosen</p>
              )}
            </div>
            <br />
            {plan?.membership?.subscription_start_date !== null ? (
              <>
                <strong>Subscription information</strong>
                <p>
                  {" "}
                  Starts :{" "}
                  {new Date(
                    plan?.membership?.subscription_start_date
                  ).toLocaleString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>
                  {" "}
                  Ends :{" "}
                  {new Date(
                    plan?.membership?.subscription_end_date
                  ).toLocaleString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
