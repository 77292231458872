import { useEffect } from "react";
import { FaCalendarAlt, FaHome, FaUserAlt } from "react-icons/fa";
import { HiSparkles } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user || user.id === null) {
      navigate("/login");
    } else if (user.plans) {
      if (user.plans.length < 0) {
        navigate("/choose-plan");
      } else if (
        !user.role &&
        (user.plans.length === 0 || user.status === false)
      ) {
        navigate("/waiting-for-confirmation");
      }
    }
  }, [user, navigate]);

  return (
    <>
      <Header />
      <div className="dashboard-wrapper">
        <div className="quick-actions">
          {user !== null ? (
            <div className="quick-actions-header">
              <h1>Welcome {user.name}</h1>
              <br />
            </div>
          ) : null}
          <div className="quick-actions-header">
            <h1>
              <span style={{ color: "#fb9230", margin: "0 4px" }}>
                <HiSparkles />
              </span>
              Quick actions
            </h1>
          </div>
          <div className="quick-actions-body">
            <Link to="/">
              <button className="action-btn">
                <FaHome /> <span>Appointments</span>
              </button>
            </Link>
            <Link to="/book-appointment">
              <button className="action-btn">
                <FaCalendarAlt /> <span>Book Appointment</span>
              </button>
            </Link>
            <Link to="/account">
              <button className="action-btn">
                <FaUserAlt /> <span>My Account</span>
              </button>
            </Link>
          </div>
        </div>
        {user ? <Outlet /> : null}
      </div>
    </>
  );
};

export default Dashboard;
