import { useEffect, useState } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { login, reset } from "../features/auth/authSlice";
import "./Main.css";
import logo from "../assets/logo_pic.PNG";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    user_password: "",
  });

  const { email, user_password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      if (message !== null || message !== "") {
        toast.error(message);
      }
      dispatch(reset);
    }
    if ((user !== null || isSuccess) && user?.role) {
      toast.success("Successfully Signed in");
      dispatch(reset);
      navigate("/admin-dash");
    } else if (isSuccess || user !== null) {
      toast.success("Successfully Signed in");
      dispatch(reset);
      navigate("/");
    } else if (user === null) {
      navigate("/login");
    }
    dispatch(reset);
  }, [user, isError, isSuccess, message, navigate, dispatch]);
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      user_password,
    };

    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <main>
        <div className="container">
          <section className="heading">
            <Link to="https://kingzbarbershop.pl" style={{ color: "#fb8500" }}>
              {" "}
              Main site{" "}
            </Link>
            <div className="logo">
              <img src={logo} alt="Kingz Barbershop" />
            </div>
            <h1>
              <FaSignInAlt /> Sign in
            </h1>
          </section>
          <section className="form">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  placeholder="Enter Email"
                  onChange={onChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="user_password"
                  value={user_password}
                  placeholder="Enter Password"
                  onChange={onChange}
                  required
                />
              </div>
              <p>
                Create a new account,{" "}
                <Link
                  to="/register"
                  className="form-link"
                  style={{ color: "#fb8500" }}
                >
                  {" "}
                  Register
                </Link>
              </p>
              <div className="form-group">
                <button type="submit" className="btn btn-block">
                  Sign in
                </button>
              </div>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};

export default Login;
