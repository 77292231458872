import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createSchedule } from "../../features/schedules/scheduleSlice";

const AddSchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    day_of_week: "",
    from: "",
    to: "",
    isAvailable: false,
  });

  const { day_of_week, from, to, isAvailable } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onCheckboxClick = (e) => {
    if (e.target.checked) {
      setFormData((prevState) => ({
        ...prevState,
        isAvailable: true,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        isAvailable: false,
      }));
    }
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const onSubmit = (e) => {
    e.preventDefault();

    if (day_of_week !== "" && from !== "" && to !== "") {
      if (days.includes(day_of_week)) {
        dispatch(
          createSchedule({
            day_of_week,
            from,
            to,
            isAvailable,
          })
        );
        toast.success("Schedule Added!");
        navigate("/admin-dash/schedules");
      } else {
        toast.error("Invalid Day of the week");
      }
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Add new schedule</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/schedules">
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Day</label>
            <input
              type="text"
              name="day_of_week"
              onChange={onChange}
              value={day_of_week}
              className="form-control"
            />
          </div>
          <div className="form-group-pair">
            <div className="form-group">
              <label>Open From</label>
              <input
                type="time"
                name="from"
                onChange={onChange}
                value={from}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Closes at</label>
              <input
                type="time"
                name="to"
                onChange={onChange}
                value={to}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label>Availability</label>
            <span style={{ margin: "5px 2px" }}>
              Make available{" "}
              <input
                type="checkbox"
                name="isAvailable"
                onClick={(e) => onCheckboxClick(e)}
              />
            </span>
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Add Schedule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSchedule;
