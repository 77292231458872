import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopUp from "../../components/helpers/PopUp";
import { CgDanger } from "react-icons/cg";
import {
  deletePlan,
  getPlans,
  updatePlan,
} from "../../features/plans/planSlice";
import { getTiers } from "../../features/tiers/tierSlice";

const UpdatePlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (plans.length === 0) {
      navigate("/admin-dash/subscriptions");
    }
    dispatch(getTiers());
  }, [dispatch, plans, navigate]);

  if (id === null) {
    navigate("/admin-dash/subscriptions");
  }
  const plan = plans.filter((item) => item.id.toString() === id.toString());

  const [formData, setFormData] = useState({
    title: plan[0]?.title,
    description: plan[0]?.description,
  });
  const tiers = plan[0]?.tiers;

  const { title, description } = formData;

  // Pop up state.
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (title !== "" && description !== "") {
      dispatch(
        updatePlan({
          id,
          title,
          description,
        })
      );
      toast.success("Subscription updated");
      navigate("/admin-dash/subscriptions");
    }
  };

  const confirmDelete = (e) => {
    e.preventDefault();

    dispatch(deletePlan(id));
    toast.success("Service Deleted!");
    navigate("/admin-dash/subscriptions");
    dispatch(getPlans());
  };

  const toggleModal = (e) => {
    e.preventDefault();

    setShowModal(!showModal);
  };

  const UpdateTiers = () => {
    return (
      <div className="services-wrapper">
        <br />
        {tiers?.map((tier, index) => (
          <div className="service-item" key={index}>
            <div className="service-name">{tier?.months}</div>
            <Link to={`/admin-dash/edit-tier/${tier.id}`}>
              <button className="item-btn">Edit</button>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="quick-actions">
      {showPopUp ? (
        <PopUp>
          <div className="popup-header">
            <CgDanger />
          </div>
          <div className="popup-body">
            Are you sure you want to delete plan <strong>{title}</strong>
            <div className="popup-btns">
              <button className="btn btn-sm btn-del" onClick={confirmDelete}>
                Confirm
              </button>
              <button className="btn btn-sm btn-sm-dark" onClick={togglePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      ) : null}
      <div className="quick-actions-header">
        <h1>Update Plan</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/subscriptions" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>{" "}
        <button className="btn btn-sm btn-del" onClick={togglePopUp}>
          Delete Plan
        </button>
        <form
          className="form"
          style={{ transition: "all 0.5s ease" }}
          onSubmit={onSubmit}
        >
          <button className="btn btn-sm btn-sm" onClick={toggleModal}>
            {showModal ? "Close" : "Edit Packages included"}
          </button>
          <br />
          {showModal ? <UpdateTiers /> : null}
          <div className="form-group">
            <label>Plan Name</label>
            <input
              type="text"
              name="title"
              onChange={onChange}
              value={title}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              className="form-control"
              value={description}
              onChange={onChange}
              cols="30"
              rows="5"
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update Plan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePlan;
