import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateSchedule } from "../../features/schedules/scheduleSlice";

const UpdateSchedule = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { schedules } = useSelector((state) => state.schedules);

  useEffect(() => {
    if (schedules.length === 0) {
      navigate("/admin-dash/schedules");
    }
  }, [dispatch, schedules, navigate]);

  if (id === null) {
    navigate("/admin-dash/schedules");
  }
  const schedule = schedules.filter(
    (item) => parseInt(item.id) === parseInt(id)
  );

  const [formData, setFormData] = useState({
    day_of_week: schedule[0]?.day_of_week,
    from: schedule[0]?.open_from,
    to: schedule[0]?.close_at,
    isAvailable: schedule[0]?.isAvailable,
  });

  const { day_of_week, from, to, isAvailable } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onCheckboxClick = (e) => {
    if (e.target.checked) {
      setFormData((prevState) => ({
        ...prevState,
        isAvailable: true,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        isAvailable: false,
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (day_of_week !== "" && from !== "" && to !== "") {
      dispatch(
        updateSchedule({
          id,
          day_of_week,
          from,
          to,
          isAvailable,
        })
      );
      toast.success("Schedule updated");
      navigate("/admin-dash/schedules");
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Update Schedule</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/schedules">
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Day</label>
            <input
              type="text"
              name="day_of_week"
              onChange={onChange}
              value={day_of_week}
              className="form-control"
            />
          </div>
          <div className="form-group-pair">
            <div className="form-group">
              <label>Open From</label>
              <input
                type="time"
                name="from"
                onChange={onChange}
                value={from}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Closes at</label>
              <input
                type="time"
                name="to"
                onChange={onChange}
                value={to}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label>Availability</label>
            <span style={{ margin: "5px 2px" }}>
              Make available{" "}
              <input
                type="checkbox"
                name="isAvailable"
                checked={isAvailable}
                onChange={(e) => onCheckboxClick(e)}
              />
            </span>
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update Schedule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateSchedule;
