import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import timeoffService from "./timeoffService";

// initial state.
const initialState = {
  timeoffs: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  oneTier: {},
};

// Create new timeoff.
export const createTimeoff = createAsyncThunk(
  "timeoff/create",
  async (timeoffData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await timeoffService.createTimeoff(timeoffData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTimeoffs = createAsyncThunk(
  "timeoff/getAll",
  async (_, thunkAPI) => {
    try {
      return await timeoffService.getTimeoffs();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const getOneTier = createAsyncThunk(
//   "tiers/getOne",
//   async (id, thunkAPI) => {
//     try {
//       return await tierService.getOneTier(id);
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.reponse.data.message) ||
//         error.message ||
//         error.toString() ||
//         error.errMessage;
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// export const updateAddon = createAsyncThunk(
//   "timeoff/update",
//   async (addonData, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token;
//       await addonService.updateAddon(addonData, token);
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.reponse.data.message) ||
//         error.message ||
//         error.toString() ||
//         error.errMessage;
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
//
export const deleteTimeoff = createAsyncThunk(
  "timeoff/delete",
  async (timeoffId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await timeoffService.deleteTimeoff(timeoffId, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const timeoffSlice = createSlice({
  name: "timeoff",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTimeoff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTimeoff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.timeoffs.push(action.payload);
      })
      .addCase(createTimeoff.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTimeoffs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTimeoffs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.timeoffs = action.payload;
      })
      .addCase(getTimeoffs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // .addCase(getOneTier.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getOneTier.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.oneTier = action.payload;
      // })
      // .addCase(getOneTier.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.message = action.payload;
      // });
      // .addCase(updateAddon.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(updateAddon.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.isError = false;
      //   state.message = "";
      // })
      // .addCase(updateAddon.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.message = action.payload;
      // })
      .addCase(deleteTimeoff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTimeoff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteTimeoff.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = timeoffSlice.actions;
export default timeoffSlice.reducer;
