import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopUp from "../../components/helpers/PopUp";
import {
  deleteService,
  updateService,
  getServices,
} from "../../features/services/serviceSlice";
import { CgDanger } from "react-icons/cg";

const UpdateService = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.services);

  useEffect(() => {
    if (services.length === 0) {
      navigate("/admin-dash/services");
    }
  }, [dispatch, services, navigate]);

  if (id === null) {
    navigate("/admin-dash/services");
  }
  const service = services.filter(
    (item) => item.id.toString() === id.toString()
  );

  const [formData, setFormData] = useState({
    name: service[0]?.name,
    translated_name: service[0]?.translated_name,
    price: service[0]?.price,
    description: service[0]?.description,
    translated_description: service[0]?.translated_description,
  });

  const { name, translated_name, price, description, translated_description } =
    formData;

  // Pop up state.
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      name !== "" &&
      price !== "" &&
      description !== "" &&
      translated_name !== "" &&
      translated_description !== ""
    ) {
      dispatch(
        updateService({
          id,
          name,
          translated_name,
          description,
          translated_description,
          price,
        })
      );
      toast.success("Service updated");
      navigate("/admin-dash/services");
    }
  };

  const confirmDelete = (e) => {
    e.preventDefault();

    dispatch(deleteService(id));
    toast.success("Service Deleted!");
    navigate("/admin-dash/services");
    dispatch(getServices());
  };

  return (
    <div className="quick-actions">
      {showPopUp ? (
        <PopUp>
          <div className="popup-header">
            <CgDanger />
          </div>
          <div className="popup-body">
            Are you sure you want to delete service <strong>{name}</strong>
            <div className="popup-btns">
              <button className="btn btn-sm btn-del" onClick={confirmDelete}>
                Confirm
              </button>
              <button className="btn btn-sm btn-sm-dark" onClick={togglePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      ) : null}
      <div className="quick-actions-header">
        <h1>Update Service</h1>
      </div>
      <div className="quick-actions-body">
        <button className="btn btn-sm btn-del" onClick={togglePopUp}>
          Delete Service
        </button>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Service Name</label>
            <input
              type="text"
              name="name"
              onChange={onChange}
              value={name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Translated name</label>
            <input
              type="text"
              name="translated_name"
              onChange={onChange}
              value={translated_name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              onChange={onChange}
              value={price}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <input
              type="text"
              name="description"
              onChange={onChange}
              value={description}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Translated Description</label>
            <input
              type="text"
              name="translated_description"
              onChange={onChange}
              value={translated_description}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update Service
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateService;
