import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppointmentItem from "../components/AppointmentItem";
import Spinner from "../components/Spinner";
import {
  getAppointments,
  reset,
} from "../features/appointments/appointmentSlice";
import { getTiers } from "../features/tiers/tierSlice";

const HeroDash = () => {
  const dispatch = useDispatch();
  const { appointments, isError, isLoading, message } = useSelector(
    (state) => state.appointments
  );

  useEffect(() => {
    dispatch(getAppointments());
    dispatch(getTiers());

    return () => {
      dispatch(reset);
    };
  }, [isError, message, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Previous Appointments</h1>
      </div>
      <div className="quick-actions-body">
        {appointments.length > 0 ? (
          <div className="appointments-wrapper">
            {appointments.map((appointment, index) => (
              <AppointmentItem key={index} appointment={appointment} />
            ))}
          </div>
        ) : (
          <p>No Appointments yet</p>
        )}
      </div>
    </div>
  );
};

export default HeroDash;
