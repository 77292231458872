import { useEffect, useState } from "react";
import { FaPlusCircle, FaSearch, FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { times } from "../data/times";
import {
  createAppointment,
  getAppointments,
} from "../features/appointments/appointmentSlice";
import { getOpenSchedules } from "../features/schedules/scheduleSlice";
import { getServices } from "../features/services/serviceSlice";
import {
  getAvailabilities,
  reset,
} from "../features/availabilities/availabilitySlice";
import { formatTime } from "../utils/convertToDisplayTime";
import { getAddons } from "../features/addons/addonSlice";

const BookAppointment = () => {
  // Local states
  const [formData, setFormData] = useState({
    serviceId: "",
    appointmentDate: "",
    appointmentTime: "",
    addonId: "",
  });

  const [toggleAddon, setToggleAddon] = useState(false);
  const [toggleBook, setToggleBook] = useState(false);

  const { serviceId, appointmentDate, appointmentTime, addonId } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { services, isError, message } = useSelector((state) => state.services);
  const { selectedDay } = useSelector((state) => state.schedules);
  const { availabilities } = useSelector((state) => state.availabilities);
  const { addons } = useSelector((state) => state.addons);

  const notify = (message) => {
    toast.error(message);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    dispatch(getServices());
    dispatch(getAddons());
    if (appointmentDate.length !== 0) {
      const dayOfWeek = new Date(appointmentDate).toLocaleString("en-us", {
        weekday: "long",
      });
      dispatch(getOpenSchedules(dayOfWeek));
    }
  }, [isError, message, appointmentDate, dispatch]);

  const onFindTime = (e) => {
    e.preventDefault();
    if (appointmentTime === "" && appointmentDate === "") {
      return;
    }

    if (appointmentDate !== null && appointmentTime !== null) {
      const newDate = new Date(appointmentDate).toISOString().split("T")[0];
      const scheduleData = {
        day: newDate,
        time: appointmentTime,
      };
      dispatch(getAvailabilities(scheduleData));
    }
  };

  const filterTimeBySchedule = () => {
    const timesWithinWindow = [];

    if (selectedDay.length) {
      const scheduleObj = selectedDay[0];
      const { close_at, isAvailable, open_from } = scheduleObj;
      const diff = Math.ceil(
        (new Date(appointmentDate).getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      );

      validateTime(scheduleObj);
      const templateDate = new Date().toISOString().split("T")[0];
      if (scheduleObj && isAvailable === true && diff > 0) {
        let isWithinWindow = false;

        times.forEach((time) => {
          if (
            new Date(`${templateDate}T${time.time}`) >=
              new Date(`${templateDate}T${open_from}:00.000Z`) &&
            new Date(`${templateDate}T${time.time}`) <
              new Date(`${templateDate}T${close_at}:00.000Z`)
          ) {
            isWithinWindow = true;
          } else {
            isWithinWindow = false;
          }
          if (isWithinWindow) {
            timesWithinWindow.push(time);
          }
        });
      }
    }
    return timesWithinWindow;
  };

  const validateTime = (selectedDay) => {
    if (selectedDay) {
      if (selectedDay.isAvailable === false) {
        notify("Selected day is not a working day");
      }
    }
  };

  const selectTime = (e) => {
    e.preventDefault();
    setFormData((prevState) => ({
      ...prevState,
      appointmentTime: e.target.value,
    }));
    setToggleBook(true);
  };

  const handleToggleAddon = (e) => {
    e.preventDefault();
    setToggleAddon(!toggleAddon);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (user && appointmentDate !== "" && appointmentTime !== "") {
      dispatch(
        createAppointment({
          appointment_date: appointmentDate,
          appointment_time: appointmentTime,
          serviceId,
        })
      );
      setFormData({
        serviceId: "",
        appointmentDate: "",
        appointmentTime: "",
      });
      dispatch(getAppointments());
      dispatch(reset());
      navigate("/");
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Book Appointment</h1>
      </div>
      <div className="quick-actions-body">
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Appointment Date</label>
            <input
              type="date"
              className="form-control"
              name="appointmentDate"
              value={appointmentDate}
              onChange={onChange}
              required
              min={new Date().toISOString().split("T")[0]}
              max={
                new Date(new Date().setDate(new Date().getDate() + 30))
                  .toISOString()
                  .split("T")[0]
              }
            />
          </div>
          <div className="form-group">
            <label>Service</label>
            <select
              name="serviceId"
              className="form-control"
              value={serviceId}
              onChange={onChange}
            >
              <option>Select Services</option>
              {services &&
                services.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name} - {item.price} PLN
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            {toggleAddon ? (
              <div className="add-on">
                <FaTimesCircle /> <p onClick={handleToggleAddon}>Close</p>
              </div>
            ) : (
              <div className="add-on">
                <FaPlusCircle /> <p onClick={handleToggleAddon}>Add-on</p>
              </div>
            )}
          </div>
          {toggleAddon ? (
            <div className="form-group">
              <label>Addon</label>
              <select
                name="addonId"
                className="form-control"
                value={addonId}
                onChange={onChange}
              >
                <option>Addon</option>
                {addons &&
                  addons.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name} - {item.price} PLN
                    </option>
                  ))}
              </select>
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label>Time</label>
            <div className="form-pair">
              <select
                name="appointmentTime"
                value={appointmentTime}
                className="form-control-2"
                onChange={onChange}
              >
                <option>Select Time</option>
                {filterTimeBySchedule().map((time, index) => (
                  <option value={time.time} key={index}>
                    {time.displayTime}
                  </option>
                ))}
              </select>
              <button className="form-control-2" onClick={onFindTime}>
                <FaSearch /> <span>Availability</span>
              </button>
            </div>
          </div>
          {availabilities.availabilities &&
          availabilities.availabilities.length ? (
            <div className="mt-4">
              <p className="text-reg">Click on one schedule to confirm</p>
              <div className="flex flex-wrap mt-2">
                {availabilities.availabilities.map((time, index) => {
                  return time.available ? (
                    <button
                      className={
                        time.time === appointmentTime
                          ? "schedule selected-schedule"
                          : "schedule"
                      }
                      onClick={selectTime}
                      value={time.time}
                      key={index}
                    >
                      {formatTime(time.time)}
                    </button>
                  ) : (
                    <button
                      className="schedule"
                      value={time.time}
                      key={index}
                      disabled
                    >
                      {formatTime(time.time)}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : null}
          {toggleBook === true ? (
            <div className="form-group">
              <button type="submit" className="form-submit">
                Confirm
              </button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default BookAppointment;
