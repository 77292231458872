import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteTimeoff } from "../../features/timeoff/timeoffSlice";

const TimeoffItem = ({ timeoff }) => {
  const { id, timeoff_date } = timeoff;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let displayDate = new Date(timeoff_date).toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const confirmDelete = (e) => {
    e.preventDefault();

    dispatch(deleteTimeoff(id));
    toast.success("Timeoff Deleted!");
    navigate("/admin-dash/schedules");
  };

  return (
    <div className="service-item">
      <div className="service-name">{displayDate}</div>
      <button
        className="item-btn"
        style={{ background: "red", color: "white" }}
        onClick={confirmDelete}
      >
        Delete
      </button>
    </div>
  );
};

export default TimeoffItem;
