import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAddon, getAddons } from "../../features/addons/addonSlice";

const AddAddon = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    translated_name: "",
    price: "",
  });

  const { name, translated_name, price } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (name !== "" && price !== "" && translated_name !== "") {
      dispatch(
        createAddon({
          name,
          translated_name,
          price,
        })
      );
      toast.success("Addon Added!");
      dispatch(getAddons());
      navigate("/admin-dash/addons");
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Add Addon</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/addons">
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Addon Name</label>
            <input
              type="text"
              name="name"
              onChange={onChange}
              value={name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Translated name</label>
            <input
              type="text"
              name="translated_name"
              onChange={onChange}
              value={translated_name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              onChange={onChange}
              value={price}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Add Addon
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAddon;
