import axios from "axios";

import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/addons`;

const createAddon = async (addonData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, addonData, config);

  return response.data;
};

const getAddons = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

const updateAddon = async (addonData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/${addonData.id}`,
    addonData,
    config,
  );
  return response;
};

const deleteAddon = async (addonId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${addonId}`, config);
  return response;
};

const addonService = {
  createAddon,
  getAddons,
  updateAddon,
  deleteAddon,
};

export default addonService;
