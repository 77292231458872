import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createService,
  getServices,
} from "../../features/services/serviceSlice";

const AddService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    translated_name: "",
    price: "",
    description: "",
    translated_description: "",
  });

  const { name, translated_name, price, description, translated_description } =
    formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      name !== "" &&
      price !== "" &&
      description !== "" &&
      translated_name !== "" &&
      translated_description !== ""
    ) {
      dispatch(
        createService({
          name,
          translated_name,
          description,
          translated_description,
          price,
        })
      );
      toast.success("Service Added!");
      dispatch(getServices());
      navigate("/admin-dash/services");
    }
  };

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Add Service</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/services">
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Service Name</label>
            <input
              type="text"
              name="name"
              onChange={onChange}
              value={name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Translated name</label>
            <input
              type="text"
              name="translated_name"
              onChange={onChange}
              value={translated_name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              onChange={onChange}
              value={price}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <input
              type="text"
              name="description"
              onChange={onChange}
              value={description}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Translated Description</label>
            <input
              type="text"
              name="translated_description"
              onChange={onChange}
              value={translated_description}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Add Service
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddService;
