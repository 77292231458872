import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlansItem from "../../components/admin/PlansItem";
import { getPlans } from "../../features/plans/planSlice";

const Subscriptions = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);

  useEffect(() => {
    dispatch(getPlans());
    if (!user || !user.role) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);
  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Subscriptions</h1>
      </div>
      <div className="quick-actions-body">
        {plans.length > 0 ? (
          <div className="services-wrapper">
            {plans.map((item, index) => (
              <PlansItem key={index} plan={item} />
            ))}
          </div>
        ) : (
          <p>No Plans in the database</p>
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
