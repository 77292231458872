import { useEffect, useState } from "react";
import { CgDanger } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopUp from "../../components/helpers/PopUp";
import { getTiers } from "../../features/tiers/tierSlice";
import {
  activateSubscription,
  deleteUser,
  getUsers,
  reset,
  updateUserStatus,
} from "../../features/users/userSlice";

const UpdateUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasUpdated, setHasUpdate] = useState(false);
  const { users, isSuccess, isError, message } = useSelector(
    (state) => state.users,
  );
  const { tiers } = useSelector((state) => state.tiers);

  useEffect(() => {
    if (users.length === 0) {
      navigate("/admin-dash/users");
    }
    dispatch(getTiers());
    if (isError) {
      toast.error(message);
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      toast.success("User status Updated");
      setHasUpdate(false);
      dispatch(getUsers());
      dispatch(reset());
      navigate("/admin-dash/users");
    }
  }, [dispatch, users, isError, isSuccess, message, navigate, hasUpdated]);

  if (id === null) {
    navigate("/admin-dash/users");
  }
  const user = users.filter((item) => item.id.toString() === id.toString());

  if (user[0] === null) {
    navigate("/admin-dash/users");
  }

  const name = user[0]?.name;
  const email = user[0]?.email;
  const phone_number = user[0]?.phone_number;
  const plan = user[0]?.plans[0];

  const tier = tiers.filter(
    (item) => item.id.toString() === plan?.membership?.tierId?.toString(),
  );

  const [formData, setFormData] = useState({
    status: user[0]?.status,
    start_date: "",
  });

  const { status, start_date } = formData;

  const onCheckboxClick = (e) => {
    if (e.target.checked) {
      setFormData((prevState) => ({
        ...prevState,
        status: true,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        status: false,
      }));
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Pop up state.
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (status !== "" && start_date !== "") {
      setHasUpdate(true);
      dispatch(
        updateUserStatus({
          id,
          status,
        }),
      );
      dispatch(
        activateSubscription({
          id: plan?.membership?.id,
          start_date,
          tier: tier[0]?.months,
        }),
      );
    }
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    dispatch(deleteUser(id));
    toast.success("User Deleted!");
    navigate("/admin-dash/users");
    dispatch(getUsers());
  };

  return (
    <div className="quick-actions">
      {showPopUp ? (
        <PopUp>
          <div className="popup-header">
            <CgDanger />
          </div>
          <div className="popup-body">
            Are you sure you want to delete <strong>{name}'s</strong> account
            <div className="popup-btns">
              <button className="btn btn-sm btn-del" onClick={confirmDelete}>
                Confirm
              </button>
              <button className="btn btn-sm btn-sm-dark" onClick={togglePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      ) : null}
      <div className="quick-actions-header">
        <h1>Update User</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/users" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>{" "}
        <button className="btn btn-sm btn-del" onClick={togglePopUp}>
          Delete User
        </button>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <strong>Name</strong>
            <p>{name}</p>
          </div>
          <div className="form-group">
            <strong>Email</strong>
            <p>{email}</p>
          </div>
          <div className="form-group">
            <strong>Phone Number</strong>
            <p>{phone_number}</p>
          </div>
          <div className="form-group package-item">
            <strong>Package</strong>
            {tier.length > 0 ? (
              <p>
                {plan?.title} - {tier[0]?.months} ({parseInt(tier[0].price)}{" "}
                PLN)
              </p>
            ) : (
              <p>No plan chosen</p>
            )}
          </div>
          <div className="form-group">
            <label>User Access</label>
            <span style={{ margin: "5px 2px" }}>
              Grant access{" "}
              <input
                type="checkbox"
                name="isAvailable"
                checked={status}
                onChange={(e) => onCheckboxClick(e)}
              />
            </span>
          </div>
          <div className="form-group">
            {plan?.membership?.subscription_start_date ? (
              <>
                <label>Subscription information</label>
                <p>
                  {" "}
                  Starts :{" "}
                  {new Date(
                    plan?.membership?.subscription_start_date,
                  ).toLocaleString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>
                  {" "}
                  Ends :{" "}
                  {new Date(
                    plan?.membership?.subscription_end_date,
                  ).toLocaleString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
              </>
            ) : (
              <>
                <label>Add subscription start date</label>
                <input
                  type="date"
                  className="form-control"
                  name="start_date"
                  onChange={onChange}
                  value={start_date}
                  required
                />
              </>
            )}
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update User Status
            </button>
            <Link to={`/admin-dash/edit-user-subscription/${id}`}>
              <button className="form-submit-outline">
                Update Subscription
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateUser;
