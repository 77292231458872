import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserItem from "../../components/admin/UserItem";
import { getTiers } from "../../features/tiers/tierSlice";
import { getUsers } from "../../features/users/userSlice";

const UserManagement = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTiers());
    if (!user || !user.role) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);

  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Users</h1>
      </div>
      <div className="quick-actions-body">
        {users.length > 0 ? (
          <div className="services-wrapper">
            {users.map((item, index) => (
              <UserItem key={index} user={item} />
            ))}
          </div>
        ) : (
          <p>No members in the database</p>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
