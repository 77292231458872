import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddonItem from "../../components/admin/AddonItem";
import { getAddons } from "../../features/addons/addonSlice";

const Addons = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { addons } = useSelector((state) => state.addons);

  useEffect(() => {
    dispatch(getAddons());
    if (!user || !user.role) {
      navigate("/login");
    }
  }, [user, navigate, dispatch]);
  return (
    <div className="quick-actions">
      <div className="quick-actions-header">
        <h1>Addons</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash/add-addon">
          <button className="btn btn-sm btn-sm-dark">Add addon</button>
        </Link>
        {addons.length > 0 ? (
          <div className="services-wrapper">
            {addons.map((item, index) => (
              <AddonItem key={index} addon={item} />
            ))}
          </div>
        ) : (
          <p>No Addons in the database</p>
        )}
      </div>
    </div>
  );
};

export default Addons;
