import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import tierService from "./tierService";

// initial state.
const initialState = {
  tiers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  oneTier: {},
};

// Create new tier.
export const createTier = createAsyncThunk(
  "tiers/create",
  async (tierData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await tierService.createTier(tierData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTiers = createAsyncThunk(
  "tiers/getAll",
  async (_, thunkAPI) => {
    try {
      return await tierService.getTiers();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneTier = createAsyncThunk(
  "tiers/getOne",
  async (id, thunkAPI) => {
    try {
      return await tierService.getOneTier(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTier = createAsyncThunk(
  "tiers/update",
  async (tierData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await tierService.updateTier(tierData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTier = createAsyncThunk(
  "tiers/delete",
  async (tierId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await tierService.deleteTier(tierId, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const tierSlice = createSlice({
  name: "tiers",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tiers.push(action.payload);
      })
      .addCase(createTier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTiers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTiers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tiers = action.payload;
      })
      .addCase(getTiers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneTier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneTier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneTier = action.payload;
      })
      .addCase(getOneTier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateTier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateTier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteTier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteTier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = tierSlice.actions;
export default tierSlice.reducer;
