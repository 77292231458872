import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopUp from "../../components/helpers/PopUp";
import { CgDanger } from "react-icons/cg";
import {
  deleteAddon,
  getAddons,
  reset,
  updateAddon,
} from "../../features/addons/addonSlice";

const UpdateAddon = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasUpdated, setHasUpdate] = useState(false);
  const { addons, isSuccess, isError, message } = useSelector(
    (state) => state.addons
  );

  useEffect(() => {
    if (addons.length === 0) {
      navigate("/admin-dash/addons");
    }
    if (isError) {
      toast.error(message);
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      toast.success("Addon List Updated");
      setHasUpdate(false);
      dispatch(getAddons());
      dispatch(reset());
      navigate("/admin-dash/addons");
    }
  }, [dispatch, addons, isError, isSuccess, message, navigate, hasUpdated]);

  if (id === null) {
    navigate("/admin-dash/addons");
  }
  const addon = addons.filter((item) => item.id.toString() === id.toString());

  const [formData, setFormData] = useState({
    name: addon[0]?.name,
    translated_name: addon[0]?.translated_name,
    price: addon[0]?.price,
  });

  const { name, translated_name, price } = formData;

  // Pop up state.
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (name !== "" && price !== "" && translated_name !== "") {
      setHasUpdate(true);
      dispatch(
        updateAddon({
          id,
          name,
          translated_name,
          price,
        })
      );
    }
  };

  const confirmDelete = (e) => {
    e.preventDefault();

    dispatch(deleteAddon(id));
    setHasUpdate(true);
  };

  return (
    <div className="quick-actions">
      {showPopUp ? (
        <PopUp>
          <div className="popup-header">
            <CgDanger />
          </div>
          <div className="popup-body">
            Are you sure you want to delete addon <strong>{name}</strong>
            <div className="popup-btns">
              <button className="btn btn-sm btn-del" onClick={confirmDelete}>
                Confirm
              </button>
              <button className="btn btn-sm btn-sm-dark" onClick={togglePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      ) : null}
      <div className="quick-actions-header">
        <h1>Update Addon</h1>
      </div>
      <div className="quick-actions-body">
        <button className="btn btn-sm btn-del" onClick={togglePopUp}>
          Delete Addon
        </button>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Addon Name</label>
            <input
              type="text"
              name="name"
              onChange={onChange}
              value={name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Translated name</label>
            <input
              type="text"
              name="translated_name"
              onChange={onChange}
              value={translated_name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              onChange={onChange}
              value={price}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="form-submit">
              Update Addon
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateAddon;
