import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import scheduleService from "./scheduleService";

// initial state.
const initialState = {
  schedules: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  selectedDay: [],
  message: "",
};

// Create new schedule 
export const createSchedule = createAsyncThunk(
  "schedule/create",
  async (scheduleData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await scheduleService.createSchedule(scheduleData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSchedules = createAsyncThunk(
  "schedule/getAll",
  async (_, thunkAPI) => {
    try {
      return await scheduleService.getSchedules();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOpenSchedules = createAsyncThunk(
  "schedule/getOpenHours",
  async (day, thunkAPI) => {
    try {
      return await scheduleService.getOpenHours(day);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  "schedule/updateSchedule",
  async (scheduleData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await scheduleService.updateSchedule(scheduleData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  "schedule/delete",
  async (scheduleId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await scheduleService.deleteSchedule(scheduleId, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.schedules.push(action.payload);
      })
      .addCase(createSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSchedules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.schedules = action.payload;
      })
      .addCase(getSchedules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOpenSchedules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOpenSchedules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.selectedDay = action.payload;
      })
      .addCase(getOpenSchedules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSchedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.schedules = [...state.schedules, action.payload];
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteSchedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.schedules = state.schedules.filter(
          (schedule) => schedule.id !== action.payload.data.id
        );
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = scheduleSlice.actions;
export default scheduleSlice.reducer;
