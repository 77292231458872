import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./pages/Dashboard.admin";
import HeroDash from "./pages/HeroDash";
import MyAccount from "./pages/MyAccount";
import BookAppointment from "./pages/BookAppointment";
import HeroAdminDash from "./pages/admin/HeroDash.admin";
import Addons from "./pages/admin/Addons";
import Services from "./pages/admin/Services";
import Schedules from "./pages/admin/Schedules";
import UserManagement from "./pages/admin/UserManagement";
import Subscriptions from "./pages/admin/Subscriptions";
import AdminAccount from "./pages/admin/AdminAccount";
import Plans from "./pages/Plans";
import UpdateSchedule from "./pages/admin/UpdateSchedule";
import UpdateService from "./pages/admin/UpdateService";
import AddService from "./pages/admin/AddService";
import AddSchedule from "./pages/admin/AddSchedule";
import ChangePasswordAdmin from "./pages/admin/ChangePasswordAdmin";
import UpdateAddon from "./pages/admin/UpdateAddon";
import AddAddon from "./pages/admin/AddAddon";
import UpdateUser from "./pages/admin/UpdateUser";
import WaitingForAccess from "./pages/WaitingForAccess";
import ChooseTier from "./pages/ChooseTier";
import ViewAppointment from "./pages/admin/ViewAppointment";
import ViewClientAppointment from "./pages/ViewClientAppointment";
import Timeoffs from "./pages/admin/Timeoffs";
import AddTimeoff from "./pages/admin/AddTimeoff";
import UpdatePlan from "./pages/admin/UpdatePlan";
import UpdateTier from "./pages/admin/UpdateTier";
import UpdateUserSubscription from "./pages/admin/UpdateUserSubscription";

const App = () => {
  return (
    <>
      <Router>
        <div className="App">
          <ToastContainer />
          <Routes>
            <Route path="/choose-plan" element={<Plans />} />
            <Route path="/choose-tier/:id" element={<ChooseTier />} />
            <Route path="/" element={<Dashboard />}>
              <Route index element={<HeroDash />} />
              <Route path="home" element={<HeroDash />} />
              <Route path="book-appointment" element={<BookAppointment />} />
              <Route path="account" element={<MyAccount />} />
              <Route
                path="view-appointment/:id"
                element={<ViewClientAppointment />}
              />
            </Route>
            <Route path="/admin-dash" element={<AdminDashboard />}>
              <Route index element={<HeroAdminDash />} />
              <Route path="home" element={<HeroAdminDash />} />
              <Route path="services" element={<Services />} />
              <Route path="addons" element={<Addons />} />
              <Route path="schedules" element={<Schedules />} />
              <Route path="timeoffs" element={<Timeoffs />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="edit-user/:id" element={<UpdateUser />} />
              <Route
                path="edit-user-subscription/:id"
                element={<UpdateUserSubscription />}
              />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="admin-account" element={<AdminAccount />} />
              <Route path="edit-schedule/:id" element={<UpdateSchedule />} />
              <Route path="edit-service/:id" element={<UpdateService />} />
              <Route path="edit-plan/:id" element={<UpdatePlan />} />
              <Route
                path="view-appointment/:id"
                element={<ViewAppointment />}
              />
              <Route path="add-service" element={<AddService />} />
              <Route path="edit-addon/:id" element={<UpdateAddon />} />
              <Route path="edit-tier/:id" element={<UpdateTier />} />
              <Route path="add-addon" element={<AddAddon />} />
              <Route path="add-schedule" element={<AddSchedule />} />
              <Route path="add-timeoff" element={<AddTimeoff />} />
              <Route path="change-password" element={<ChangePasswordAdmin />} />
              <Route path="*" element={<HeroAdminDash />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route
              path="waiting-for-confirmation"
              element={<WaitingForAccess />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
