import { Link } from "react-router-dom";
import { BsFillEyeSlashFill } from "react-icons/bs";

const ScheduleItem = ({ schedule }) => {
  const { id, open_from, close_at, isAvailable } = schedule;

  return (
    <>
      {isAvailable === true ? (
        <div className="service-item">
          <div className="service-name">
            {schedule.day_of_week}{" "}
            <span style={{ color: "grey" }}>
              ({open_from} - {close_at})
            </span>
          </div>
          <Link to={`/admin-dash/edit-schedule/${id}`}>
            <button className="item-btn">Edit</button>
          </Link>
        </div>
      ) : (
        <div className="service-item unavailable">
          <div className="service-name">
            {schedule.day_of_week}{" "}
            <span
              style={{
                color: "grey",
              }}
            >
              ({open_from} - {close_at}) <BsFillEyeSlashFill />
            </span>
          </div>
          <Link to={`/admin-dash/edit-schedule/${id}`}>
            <button className="item-btn">Edit</button>
          </Link>
        </div>
      )}
    </>
  );
};

export default ScheduleItem;
