import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import availabilityService from "./availabilityService";

// initial state.
const initialState = {
  availabilities: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new service.
export const getAvailabilities = createAsyncThunk(
  "availability/getAvailability",
  async (scheduleData, thunkAPI) => {
    try {
      return await availabilityService.getAvailabilities(scheduleData);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailabilities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAvailabilities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.availabilities = action.payload;
      })
      .addCase(getAvailabilities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = availabilitySlice.actions;
export default availabilitySlice.reducer;
