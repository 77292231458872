import axios from "axios";
import { baseUrl } from "../../utils/config";

const API_URL = `${baseUrl}/timeoff`;

const createTimeoff = async (timeoffData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, timeoffData, config);

  return response.data;
};

const getTimeoffs = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

// const getOne = async (id) => {
//   const response = await axios.get(`${API_URL}/${id}`);
//
//   return response.data;
// };

// const updateAddon = async (addonData, token) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//
//   const response = await axios.put(
//     `${API_URL}/${addonData.id}`,
//     addonData,
//     config
//   );
//   return response;
// };

const deleteTimeoff = async (timeoffId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}/${timeoffId}`, config);
  return response;
};

const addonService = {
  createTimeoff,
  getTimeoffs,
  deleteTimeoff,
};

export default addonService;
