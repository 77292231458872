import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { register, reset } from "../features/auth/authSlice";
import logo from "../assets/logo_pic.PNG";

import "./Main.css";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    user_password: "",
    confirmPassword: "",
  });

  const [canRegister, setCanRegister] = useState(false);

  const { name, email, phoneNumber, user_password, confirmPassword } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate("/choose-plan");
    }
    dispatch(reset);
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onCheckboxClick = (e) => {
    if (e.target.checked) {
      setCanRegister(true);
    } else {
      setCanRegister(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (user_password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      const userData = {
        name,
        email,
        phone_number: phoneNumber,
        user_password,
      };

      dispatch(register(userData));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="container">
        <section className="heading">
          <Link to="https://kingzbarbershop.pl" style={{ color: "#fb8500" }}>
            Main site{" "}
          </Link>
          <div className="logo">
            <img src={logo} alt="Kingz Barbershop" />
          </div>
          <h1>
            <FaUser /> Register
          </h1>
        </section>
        <section className="form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={name}
                placeholder="Enter Name"
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={email}
                placeholder="Enter Email"
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                placeholder="Enter Phone Number"
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                name="user_password"
                value={user_password}
                placeholder="Enter Password"
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              <p style={{ textAlign: "center" }}>
                I already have an account,{" "}
                <Link to="/login" className="form-link">
                  {" "}
                  Log in
                </Link>
              </p>
            </div>
            <div className="form-group">
              <div
                style={{ margin: "10px 0" }}
                className="make-appointment-btn"
              >
                <input
                  type="checkbox"
                  name="tos"
                  id="tos"
                  checked={canRegister}
                  onChange={(e) => onCheckboxClick(e)}
                  required
                />{" "}
                <p>
                  I accept the{" "}
                  <Link
                    to="https://app.termly.io/document/terms-of-service/137945d4-7a11-4dc0-98fa-328474bb3270"
                    target="_blank"
                    className="links"
                  >
                    terms of service
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="https://app.termly.io/document/privacy-policy/90cff781-f912-4ee4-b5aa-b7710da4ab26"
                    target="_blank"
                    className="links"
                  >
                    privacy policy
                  </Link>
                </p>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={!canRegister}
                className="btn btn-block"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default Register;
