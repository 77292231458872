import { useState, useEffect } from "react";
import { CgDanger } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PopUp from "../../components/helpers/PopUp";
import {
  deleteAppointment,
  getAppointments,
} from "../../features/appointments/appointmentSlice";

const ViewAppointment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appointments, isSuccess, isError, message } = useSelector(
    (state) => state.appointments
  );
  const [hasUpdated, setHasUpdate] = useState(false);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      setHasUpdate(false);
    }
    if (isSuccess === true && hasUpdated === true) {
      setHasUpdate(false);
      dispatch(getAppointments());
      navigate("/");
    }
  }, [dispatch, isError, isSuccess, message, navigate, hasUpdated]);

  const appointment = appointments.filter(
    (item) => parseInt(item.id) === parseInt(id)
  );

  const name = appointment[0]?.name;
  const appointment_date = new Date(appointment[0]?.appointment_date);
  const phone_number = appointment[0]?.phone_number;
  const email = appointment[0]?.email;

  // Timezone fix.
  const newReturnedDate = new Date(appointment_date).toISOString();
  const timeZone = new Date().getTimezoneOffset() / 60;
  const timeArray = newReturnedDate.split("T")[1].split(":");
  const hourFromTime = timeArray[0];
  const newHour = parseInt(hourFromTime) + timeZone;

  const newTime = `${("0" + newHour).slice(-2)}:${timeArray[1]}:00.000Z`;
  const newDisplayDate = new Date(
    `${newReturnedDate.split("T")[0]}T${newTime}`
  );

  let displayTime = newDisplayDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  let displayDate = newDisplayDate.toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // Pop up state.
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    dispatch(deleteAppointment(id));
    toast.success("Appointment Deleted!");
    navigate("/admin-dash");
    dispatch(getAppointments());
  };

  return (
    <div className="quick-actions">
      {showPopUp ? (
        <PopUp>
          <div className="popup-header">
            <CgDanger />
          </div>
          <div className="popup-body">
            Are you sure you want to delete this appointment
            <div className="popup-btns">
              <button className="btn btn-sm btn-del" onClick={confirmDelete}>
                Confirm
              </button>
              <button className="btn btn-sm btn-sm-dark" onClick={togglePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      ) : null}
      <div className="quick-actions-header">
        <h1>View Appointment</h1>
      </div>
      <div className="quick-actions-body">
        <Link to="/admin-dash" style={{ margin: "0 5px" }}>
          <button className="btn btn-sm btn-sm-dark">Return back</button>
        </Link>{" "}
        <button className="btn btn-sm btn-del" onClick={togglePopUp}>
          Delete Appointment
        </button>
        <form className="form">
          <div className="form-group">
            <strong>Name</strong>
            <p>{name}</p>
          </div>
          <div className="form-group">
            <strong>Appointment date</strong>
            <p>
              {displayDate}, {displayTime}
            </p>
          </div>
          <div className="form-group">
            <strong>Phone Number</strong>
            <p>{phone_number}</p>
          </div>
          <div className="form-group">
            <strong>Email</strong>
            <p>{email}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewAppointment;
