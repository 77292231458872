import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPlans } from "../features/plans/planSlice";
import "./Plans.css";
import Spinner from "../components/Spinner";

const PlanItem = ({ plan }) => {
  const { title, description, tiers } = plan;
  return (
    <div className="plan-item">
      <div className="plan-header">
        <h2>{title}</h2>
      </div>
      <div className="plan-services">
        <p>{description}</p>
      </div>
      <div className="plan-body">
        <ol>
          {tiers.map((tier, index) => (
            <li key={index}>
              <strong>{tier.months}</strong> - {tier.price} PLN
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

const Plans = () => {
  const dispatch = useDispatch();
  const { plans, isError, isLoading, message } = useSelector(
    (state) => state.plans
  );

  useEffect(() => {
    dispatch(getPlans());
    if (isError) {
      console.log(message);
    }
    dispatch(getPlans());
  }, [dispatch, isError, message]);
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="plans-container">
      <br />
      <h1>Choose Package</h1>
      <br />
      <div className="plans-wrapper">
        {plans.length > 0 ? (
          plans.map((plan, index) => (
            <Link key={index} to={`/choose-tier/${plan.id}`}>
              <PlanItem plan={plan} />
            </Link>
          ))
        ) : (
          <p>Oops</p>
        )}
      </div>
    </div>
  );
};

export default Plans;
