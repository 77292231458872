import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import serviceService from "./serviceService";

// initial state.
const initialState = {
  services: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new service.
export const createService = createAsyncThunk(
  "service/create",
  async (serviceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceService.createService(serviceData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getServices = createAsyncThunk(
  "service/getAll",
  async (_, thunkAPI) => {
    try {
      return await serviceService.getServices();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateService = createAsyncThunk(
  "service/update",
  async (serviceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await serviceService.updateService(serviceData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.reponse.data.message) ||
        error.message ||
        error.toString() ||
        error.errMessage;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteService = createAsyncThunk(
  "service/delete",
  async (serviceId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      await serviceService.deleteService(serviceId, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services.push(action.payload);
      })
      .addCase(createService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services = action.payload;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = serviceSlice.actions;
export default serviceSlice.reducer;
